.modal {
  margin: 0px !important;
  padding: 20px !important;
  font-family: "FuturaPT-Medium";
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 20px;
}

.modalHeading {
  font-family: "FuturaPT-Book";
  font-size: 22px;
}

.rowRight {
  display: flex;
  justify-content: flex-end;
}

.guestName {
  font-family: "FuturaPT-Medium";
  font-size: 20px;
  padding: 30px;
}

.eventCtn {
  margin: 10px;
}

.eventTitle {
  font-family: "FuturaPT-Medium";
  font-size: 20px;
  border-bottom: solid 1px #000;
  margin-top: 20px;
}

.eventAddress {
  margin: 10px 0;
  letter-spacing: .5px;
}

input[type="radio"] {
  height: 1.2rem;
  width: 1.2rem;
  margin: 0 0.5rem 0 0.5rem;
  accent-color: #000;
}

button:disabled,
button[disabled]{
  border: 1px solid #e9dcc8;
  background-color: #e9dcc8;

}

.row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.selected {
  box-shadow: none !important;
  color: #fff;
  background: #343a40 !important;
  border-color: #343a40 !important;
}

.spinner_V8m1{
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite
}

.spinner_V8m1 circle {
  stroke-linecap: round;
  animation:spinner_YpZS 1.5s ease-in-out infinite
}

@keyframes spinner_zKoa{
  100% {
    transform: rotate(360deg)
  }
}

@keyframes spinner_YpZS{ 
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset:0
  }
  47.5% {
    stroke-dasharray:42 150;
    stroke-dashoffset:-16
  }
  95%,100% { 
    stroke-dasharray:42 150;
    stroke-dashoffset:-59
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    /* height: 100vh; */
  }

  .eventAddress {
    margin: 0;
  }
  .eventCtn {
    margin: 5px;
  }
}