@font-face {
  font-family: "FuturaPT-Book";
  src: local("FuturaPT-Book"), url(./Fonts/FuturaPT-Book.eot);
  src: url("./Fonts/FuturaPT-Book.eot?#iefix") format("embedded-opentype"),
    url("./Fonts/FuturaPT-Book.woff2") format("woff2"),
    url("./Fonts/FuturaPT-Book.woff") format("woff"),
    url("./Fonts/FuturaPT-Book.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaPT-Medium";
  src: local("FuturaPT-Medium"), url("./Fonts/FuturaPT-Medium.eot");
  src: url("./Fonts/FuturaPT-Medium.eot?#iefix") format("embedded-opentype"),
    url("./Fonts/FuturaPT-Medium.woff2") format("woff2"),
    url("./Fonts/FuturaPT-Medium.woff") format("woff"),
    url("./Fonts/FuturaPT-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Alex Brush";
  src: url("https://fonts.gstatic.com/s/alexbrush/v20/SZc83FzrJKuqFbwMKk6EhUXz7RlNiCY.woff2")
    format("woff2");
}

@font-face {
  font-family: "Allison Script";
  src: url("./Fonts/Allison_Script.otf") format("opentype");
}

@font-face {
  font-family: "Bon Vivant";
  src: url("./Fonts/BonVivant.otf") format("opentype");
}

@font-face {
  font-family: "Bon Vivant Serif";
  src: url("./Fonts/BonVivantSerif.otf") format("opentype");
}

@font-face {
  font-family: "Bon Vivant Serif Bold";
  src: url("./Fonts/BonVivantSerifBold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "FuturaPT-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.75em;
  overflow: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}