.rsvp {
  padding: 0 100px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

.rsvpCtn {
  margin-top: 30px;
  background-color: #fff;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.rsvpCtn h2 {
  font-weight: 400;
  text-align: center;
}

.goldButton {
  display: inline-block;
  border-width: 1px;
  background: #bd945a;
  cursor: pointer;
  line-height: 1.5;
  padding: 11px 16px;
  font-size: 14px;
  border-color: #bd945a;
  color: #FFF;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.goldButton:hover {
  box-shadow: none !important;
  color: #fff;
  background: #aa8551 !important;
}

.rsvp input {
  font-family: "FuturaPT-Medium";
  padding: 6px 12px;
  font-size: 20px;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.rsvp input:focus{
  background-color: #fff;
  border-color: #666;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

@media only screen and (max-width: 600px) {
  .rsvp {
    padding: 85px 30px 0 30px;
    width: 100%;
  }
  .rsvpCtn {
    margin-top: 20px;
  }
  
  .rsvpCtn h2 {
    text-align: center;
  }
  .rsvpCtn .row {
    flex-direction: column;
  }

  .rsvp input {
    width: 100%;
    box-sizing: border-box;
  }

  .rsvpCtn button {
    margin-top: 20px;
    width: 100%;
    color: #fff;
    background: #343a40;
    border-color: #343a40;
  }
  
}