.weekend {
  padding: 0 120px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 75%;
}

.timelineTitle {
  font-size: 21px;
  margin-top: 15px;
  text-transform: uppercase;
  color: #000;
  font-family: 'FuturaPT-Medium';
  font-weight: 400;
  line-height: 1.25em;
}

.container {
  width: 100%;
  /* margin: 0px auto; */
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.container a {
  text-decoration: none;
}

.timelineImg {
  width: 160px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.timeline-block {
  margin: 20px 20px 20px 20px;
  display: flex;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-sizing: border-box;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  padding: 0px 15px 15px 15px;
  color: #666;
}

.timeline-content h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500;
}

.timeline-content span {
  font-size: 15px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}


@media screen and (max-width: 768px) {
  .weekend {
    padding: 60px 10px 0px 10px;
    width: 100%;
  }
  .container {
    margin: 10px auto;
    padding-bottom: 0;
  }
  .weekend .title {
    margin-left: 20px;
  }
  .timelineTitle {
    font-size: 15px;
    margin-top: 10px;
  }
  .timeline-content {
    padding-bottom: 10px;
  }
}
