.hotel {
  padding: 0 60px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 75%;
}

.hotel .tileTitle {
  margin-bottom: 5px;
  margin-top: 5px;
}

.hotelCtn { 
  background-color: #FFF;
  margin-top: 30px;
  width: 100%;
  display: flex;
  box-sizing: border-box; 
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
}

.hotelCtn img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.hotelRow {
  display: flex;
  justify-content: center;
  padding: 10px 30px 5px 30px;
  justify-content: space-between;
}

.hotelInfo {
  color: #666;
  min-width: 250px;
}

 a {
  color: #666;
}

.info {
  width: 100%;
  color: #666;
  /* font-size: 12px; */
}

.info ul {
  padding-inline-start: 20px;
}

.slick-list {
  margin: 10px -20px !important;
}

.slick-slide > div {
  padding: 20px 20px !important;
}

.slick-track {
  display: flex;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.slick-next:before, .slick-prev:before {
  color: #000;
}

@media only screen and (max-width: 600px) {
  .hotel {
    padding: 85px 30px 30px 30px; 
    width: 100%;
  }
  .hotelCtn {
    margin-top: 0px;
    height: 100%;
  }
  .hotelRow {
    padding: 10px 20px 20px 20px;
    flex-direction: column;
  }
  .info {
    font-size: 12px;
  }
}