.details {
  padding: 0 60px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 75%;
}

.title {
  font-family: "Bon Vivant";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
}

.tileCtn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
}

.tile {
  background-color: #FFF;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 10px;
  border-radius: 20px;
}

.tile img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.tileImage img {
  width: 100%;
}

.tileTitle {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #000;
  font-family: 'FuturaPT-Book';
  line-height: 1.25em;
  letter-spacing: 0.5px;
}

.tileBody {
  padding: 0px 30px 20px 30px;
  font-family: 'FuturaPT-Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75em;
  color: #666;
  margin: 0;
  width: 50%;
}

.buttonRow {
  display: flex;
  margin-top: 15px;
  justify-content: space-evenly;
}

.blackButton {
  display: inline-block;
  margin-left:10px;
  margin-right:10px;
  border-width: 1px;
  background: #fff;
  cursor: pointer;
  line-height: 1.5;
  padding: 11px 16px;
  font-size: 14px;
  border-color: #343a40;
  color: #343a40;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.blackButton:hover {
  box-shadow: none !important;
  color: #fff;
  background: #343a40 !important;
  border-color: #343a40 !important;
}

.tileAddress {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tileAddress a {
  text-decoration: none;
  font-family: 'FuturaPT-Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75em;
  color: #666;
}

.slider {
  margin-top: 15px;
}

@media only screen and (max-width: 600px) {
  .details {
    width: 100%;
  }
  
  .tile {
    margin-top: 20px;
  }
  .title {
    font-size: 35px;
  line-height: 35px;
  }
  .tileCtn {
    flex-direction: column;
    margin-top: 15px;
  }
  .tileTitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .details {
    padding: 70px 30px 0 30px;
  }
  .tileBody {
    box-sizing: border-box;
    width: 100%;
    padding: 0 25px 0px 25px;
    font-size: 12px;
  }
  .tileAddress {
    margin: 0;
    line-height: 20px;
  }
  .tileAddress a {
    font-size: 12px;
  }

  .schedule {
    line-height: 20px;
  }
  
}