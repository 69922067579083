.faq {
  padding: 0 60px;
  box-sizing: border-box;
  width: 75%;
  margin: 0 auto;
}

.faqCtn {
  margin-top: 30px;
  background-color: #fff;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  box-sizing: border-box;
}

.faqCtn ul {
  padding-inline-start: 20px;
  margin-block-start: 5px;
  margin-block-end: 5px;
  color: #666;
}

.faqCtn .tileTitle {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 18px;
  /* text-align: center; */
}


@media only screen and (max-width: 600px) {
  .faq {
    padding: 85px 30px 30px 30px; 
    font-size: 12px;
    width: 100%;
  }
  .faqCtn {
    margin-top: 0px;
    height: 100%;
  }
  .faqCtn h2 {
    text-align: center;
  }
  .faqCtn .row {
    flex-direction: column;
  }
}