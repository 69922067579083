.main {
  background-color: #faf8f7;;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.scrollCtn {
  width: 100%;
  float: right;
  overflow: scroll;
  height: 100vh;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media only screen and (max-width: 600px) {
  .scrollCtn {
    width: 100%;
  }
}