.slide {
  scroll-snap-align: center;
  display: block;
  min-height: 100vh;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #FFF;
}

.heading {
  text-align: center;
  font-family: "Allison Script";
  line-height: 1.2em;
  letter-spacing: 0px;
  text-transform: none;
  font-weight: 400;
  font-size: 100px;
  line-height: 1em;
}

.date {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 5px;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
}

.arrow {
  position: relative;
  bottom: 100px;
  color: #FFF;
}

@media only screen and (max-width: 600px) {
  .overlay {
    display: flex;
  }
  .slide {
    justify-content: unset;
  }
}