.linklist {
  display: flex;
  flex-direction: column;
  animation: fadeIn 2s;
}

.linklist span {
  text-align: center;
  margin-top: 15px;
}

.linklist span a {
  font-family: "FuturaPT-Medium", sans-serif;
  padding: 0;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  font-size: 14px;
  letter-spacing: 5px;
  color: #000;
  border-bottom: 1px solid transparent;

}

.linklist span a:hover {
  cursor: pointer;
  border-bottom: 1px solid #BD945A;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.sidebar {
  padding: 60px 20px;
  width: 20%;
  position: fixed;
  top: 0;
  left: 0;
  /* bottom: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #fff;
  overflow-y: hidden;
  /* z-index: 1001;x */
  background: #f6f1f0;
  box-sizing: border-box;
}

.logo {
  font-size: 85px;
  font-family: "Bon Vivant";
  font-size: 40px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2em;
  color: #bd945a;
  animation: fadeIn 2s;
}

.logo span {
  margin-bottom: 20px;
  text-align: center;
}

.logo img {
  margin-bottom: 0px;
}

.logo h6 {
  font-family: "FuturaPT-Medium", sans-serif;
  font-weight: 400;
  text-transform: none;
  text-align: center;
  font-size: 12px;
  letter-spacing: 5px;
  color: #000;
  margin-bottom: 5px;
  margin-top: 0px;
  line-height: 20px;
}

.openBtn {
  display: block;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 101;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0; 
    /* bottom: 0; */
    z-index: 100;
    width: 100%;
    overflow: hidden;
  }
  .linklist {
    animation: fadeIn 0.5s;
  }
}