.gallery {
  margin: 0 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: hidden;
  overflow-x: scroll;
  scroll-snap-align: start;
}

.react-photo-album {
  overflow: hidden;
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  .gallery {
    padding: 80px 30px 30px 30px;
    margin: 0;
  }
}